<template>
  <div
    id="wrapper-mobile-size"
  >
    <div class="mobile-size">
      <img :src="require('@/assets/images/pages/rejection.svg')">
      <div class="greetings">
        Mohon maaf, anda tidak memiliki akses ke halaman ini.
      </div>
      <div>
        <b-button
          variant="gradient-success"
          class="mt-2"
          @click="$router.back()"
        >
          <feather-icon icon="ArrowLeftIcon" /> <span>Kembali</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  #wrapper-mobile-size {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #E5E5E5;
  }

  .mobile-size {
    width: 100vw;
    height: 100vh;
    background-color: #F9F9F9;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 28px;
  }

  .mobile-size img {
    height: 160px;
    width: 160px;
    margin-bottom: 36px;
  }

  .mobile-size .greetings {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #1B1B1B;
  }

  .mobile-size .greetings span {
    color: #4FBBEA;
  }

   .mobile-size .info {
     margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    /* primary */

    color: #1B1B1B;

   }

   .mobile-size .info span {
      color: #4FBBEA;
    }
</style>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
}
</script>
